import React, { useState } from "react"
import Section from "./section"
import img1 from "../assets/images/foto-1.png"
import img2 from "../assets/images/foto-2.png"
import img3 from "../assets/images/foto-3.png"
import img4 from "../assets/images/foto-4.png"
import img5 from "../assets/images/foto-5.png"
import img6 from "../assets/images/foto-6.png"

const Media = () => {
  const imgs = [
    {
      src: img1,
      caption:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      src: img2,
      caption:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores",
    },
    {
      src: img3,
      caption:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores",
    },
    {
      src: img4,
      caption:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores",
    },
    {
      src: img5,
      caption:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores",
    },
    {
      src: img6,
      caption:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores",
    },
  ]

  const [position, setPosition] = useState(0)

  return (
    <Section bgcolor="bg-gradient-to-t from-sky-50">
      <div className="flex flex-col w-full mx-auto max-w-2xl gap-6">
        <h1 className="text-4xl font-ubuntu text-center">Galería</h1>
        <div className="flex flex-row justify-center w-full">
          <button
            onClick={() => setPosition(position - 1)}
            className="bg-blue-800 text-white w-16"
          >
            {"<"}
          </button>
          <img
            className="aspect-video object-cover max-w-xl w-4/5"
            src={imgs[position % imgs.length].src}
          />
          <button
            onClick={() => setPosition(position + 1)}
            className="bg-blue-800 text-white w-16"
          >
            {">"}
          </button>
        </div>
        <div className="w-full">{imgs[position % imgs.length].caption}</div>
      </div>
    </Section>
  )
}
export default Media
