import React from "react"
import TopBanner from "../components/top-banner"
import Header from "../components/header/header"
import Media from "../components/media"
import Footer from "../components/footer"

const Gallery = () => {
  return (
    <div className="flex flex-col">
      <TopBanner />
      <Header />
      <Media />
      <Footer />
    </div>
  )
}
export default Gallery
